import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './global.less';

import {
  StrictMode,
  useEffect,
  useMemo,
  useState,
  useRef,
  useContext,
} from 'react';
import { IConfig, IConfigApp, IEnvironment } from './interfaces/environment';
import configJson from './config.json';
import { UserService } from './services/UserService';
import { IRole } from './interfaces/role';
import { PageLoading } from '@ant-design/pro-layout';
import { ConfigProvider, Modal } from 'antd';
import { defaultLanguage } from './i18n/i18n';
import GraphqlService from './services/graphql/GraphqlService';
import { IUser, IUserFirebase } from './interfaces/user';
import { Language } from './interfaces/language';
import { ContextApp } from './contexts/ContextApp';
import AppContent from './AppContent';
import { CustomMessage } from './hooks';
import { EnumsValues } from './enums/EnumsValues';
import esES from 'antd/es/locale/es_ES';
import { useTranslation } from 'react-i18next';
import { TParameter, TReturn } from './i18n/i18n.model';
import { StringMap, TOptions } from 'i18next';
import { ITenant } from './interfaces';
import { INotifications } from './interfaces/Notification';
import { NotficationService } from './services/NotificationService';
import WhatsAppFloatingActionButton from './components/common/WhatsAppFloatingActionButton';
import NotificationContextProvider from './contexts/NotificationContextProvider';
import { useServiceWorker } from './hooks/useServiceWorker';
import { IFeatureFlagConfiguration } from './interfaces/FeatureFlag';
import TourContextProvider from './contexts/TourContextProvider';
import FreshSurvey from './components/common/FreshSurvey/FreshSurvey';
import 'moment/locale/es';
import { notificationContext } from './contexts/NotificationContext';
import useClarity from './hooks/thirdPartyScripts/useClarity';

require('dotenv').config();

declare global {
  interface Window {
    firebaseui: any;
  }
}

Modal.defaultProps = {
  centered: true,
};

const App: React.FC = () => {
  useClarity();
  setupIonicReact({ scrollAssist: false });
  const [configApp] = useState<IConfigApp>(configJson);
  const [user, setUser] = useState<IUser | undefined>();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [functions, setFunctions] = useState<string[]>([]);
  const [featureFlags, setFeatureFlags] =
    useState<IFeatureFlagConfiguration | undefined>();
  const [environment, setEnvironment] = useState<IEnvironment>();
  const [config, setConfig] = useState<IConfig>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [mainMenuCollapsed, setMainMenuCollapsed] = useState(
    window.innerWidth < 992,
  );
  const [menuBreakpointCollapsed, setMenuBreakpointCollapsed] = useState(
    window.innerWidth < 992,
  );
  const [languages, setLanguages] = useState<Language[]>([]);
  const { authenticate } = UserService();
  const { customRequest, Query } = GraphqlService();
  const [userDataFirebase, setUserDataFirebase] =
    useState<IUserFirebase | undefined>(undefined);
  const [pictureProfileUrl, setPictureProfileUrl] = useState<string>('');
  const [tenantsAssociatedToUser, setTenantsAssociatedToUser] = useState<
    ITenant[]
  >([]);
  const [selectedTenantId, setSelectedTenantId] = useState<number>();
  const [availableQuota, setAvailableQuota] = useState<number>();
  const [notifications, setNotifications] = useState<INotifications>({
    missBillingData: false,
    missConfigurationOfTheIntegration: false,
    missDataInDecisionTable: false,
    quotaIsExpired: false,
    thereIsNotQuota: false,
  });
  const [whatsAppNumber, setWhatsAppNumber] = useState<string>('');
  const { getNotifications } = NotficationService();

  const defaultEnvironmentCode = configJson.default_environment;
  const defaultEnvironment = (
    configJson.environments as {
      [key: string]: IEnvironment;
    }
  )[defaultEnvironmentCode];
  const defaultConfig = configJson.default_config;
  const [currentRoute, setCurrentRoute] = useState<string>('');
  const {
    workerFinished,
    reloadPage,
    postponeFinishedNotification,
    showWorkerNotification,
  } = useServiceWorker({
    currentRoute,
    disableAutoUpdate: process.env.DISABLE_WORKER_AUTO_UPDATE === 'TRUE',
    disableNotification: process.env.DISABLE_WORKER_NOTIFICATION === 'TRUE',
  });
  const seenToursRef = useRef<number[]>([]);
  const [i18nT, i18n] = useTranslation();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  const checkAuth = async (lastTenantId?: number): Promise<any> => {
    setShowLoading(true);
    try {
      const res = await authenticate(lastTenantId);

      if (res) {
        let user: IUser = {
          email: res.email,
          id: res.id,
          password: '',
          firstname: res.firstname,
          lastname: res.lastname,
          status: res.status,
          roles: res.role,
          is_system_user: res.is_system_user,
          profile_id: res.profile_id,
          profile: res.profile,
          uid: res.uid,
          profile_picture_id: res.profile_picture_id,
          two_factor_auth_active: res.two_factor_auth_active,
          signature_id: res.signature_id,
          validated: res.validated,
          last_tenant_id: res.last_tenant_id,
        };
        const tenants_associated_with_user =
          await getTenantsAssociatedWithUser();
        const tenant = tenants_associated_with_user?.find(
          (tenant) => tenant.id === res.last_tenant_id,
        );
        if (tenant) {
          const dataNotifications = await getNotifications(tenant);
          if (dataNotifications) setNotifications(dataNotifications);
        }
        setSelectedTenantId(res.last_tenant_id);
        setUser(user);
        await getSeenTours();
        setRoles(res.roles);
        if (res.two_factor_auth_active) {
          const validate2FA = localStorage.getItem(
            EnumsValues.LocalStorageKeys.TwoFactorValidated,
          );
          if (!validate2FA) {
            localStorage.setItem(
              EnumsValues.LocalStorageKeys.TwoFactorValidated,
              EnumsValues.TwoFactorValidatedValues.False,
            );
          }
        }

        setFunctions(res.permissions);
      }
    } catch (error: any) {
      await openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'App.checkAuth.1',
      });
    }
    setShowLoading(false);
  };

  const getFeatureFlags = async () => {
    try {
      const data: IFeatureFlagConfiguration = await customRequest({
        query: Query.getAllFeatureFlagsConfig,
      });
      setFeatureFlags(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    getFeatureFlags();
  }, []);

  const getLanguages = async () => {
    try {
      const data: Language[] = await customRequest({
        query: Query.languages,
      });
      setLanguages(() => data);
      if (!data.find((item) => item.language_code === i18n.language)) {
        i18n.changeLanguage(defaultLanguage);
        i18n.loadLanguages(data.map((item) => item.language_code));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  useEffect(() => {
    if (workerFinished && showWorkerNotification) {
      Modal.confirm({
        content: (
          <>
            <div>Hay una actualización disponible</div>
          </>
        ),
        cancelText: 'No gracias',
        okText: 'Actualizar',
        maskClosable: true,
        onOk: () => reloadPage(),
        onCancel: () => postponeFinishedNotification(),
      });
    }
  }, [workerFinished, showWorkerNotification]);
  const getSeenTours = async () => {
    try {
      const data: { seenTours: number[] } = await customRequest({
        query: Query.seenTours,
      });
      seenToursRef.current = data.seenTours;
    } catch (error) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'App.getSeenTours.1',
      });
    }
  };

  useEffect(() => {
    getLanguages();
    let token = localStorage.getItem(EnumsValues.LocalStorageKeys.Token);
    let lastTenantId = localStorage.getItem(
      EnumsValues.LocalStorageKeys.LastTenantId,
    );

    if (!user && token) {
      checkAuth(lastTenantId ? Number(lastTenantId) : undefined);
    } else {
      setShowLoading(() => false);
    }
  }, []);

  const currentLanguage = useMemo(() => {
    switch (i18n.language) {
      // TODO: Descomentar para incluir traducciones en inglés de antd
      // case 'en':
      //   return enUS;
      case 'es':
        return esES;
      default:
        return esES;
    }
  }, [i18n.language]);

  function t<S extends string>(
    p: TParameter<S>,
    options?: TOptions<StringMap>,
  ): TReturn<S> {
    return i18nT(p, { ...options, fallbackLng: 'es' });
  }

  const isNumeric = (num: any) => {
    if (
      (typeof num === 'number' ||
        (typeof num === 'string' && num.trim() !== '')) &&
      !isNaN(num as number)
    )
      return true;

    return false;
  };

  const getTenantsAssociatedWithUser = async (): Promise<ITenant[]> => {
    let data: ITenant[] = [];
    try {
      data = await customRequest({
        query: Query.tenantsAssociatedWithUser,
      });
      setTenantsAssociatedToUser(data);
    } catch (error: any) {
      //Intentional
    }
    return data;
  };

  const getAvailableQuota = async (tenant_id: number) => {
    try {
      const data = await customRequest({
        mutation: Query.availableQuota,
        variables: {
          tenant_id,
        },
      });
      setAvailableQuota(data.count);
    } catch (error) {
      //Intentional
    }
  };

  const getWhatsappNumber = async () => {
    try {
      const data = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.whatsAppNumber },
        },
      });
      setWhatsAppNumber(data.setting_value);
    } catch (error) {
      //Intentional
    }
  };

  useEffect(() => {
    getWhatsappNumber();
  }, []);

  useEffect(() => {
    if (selectedTenantId) {
      getAvailableQuota(selectedTenantId);
    }
  }, [selectedTenantId]);

  return (
    <ContextApp.Provider
      value={{
        configApp,
        user,
        setUser,
        setLoading,
        loading,
        loadingMessage,
        setLoadingMessage,
        setShowLoading,
        showLoading,
        environment: environment || defaultEnvironment,
        setEnvironment,
        roles,
        setRoles,
        functions,
        setFunctions,
        featureFlags,
        setFeatureFlags,
        config: config || defaultConfig,
        setConfig,
        dataLoaded,
        setDataLoaded,
        checkAuth,
        mainMenuCollapsed,
        setMainMenuCollapsed,
        menuBreakpointCollapsed,
        setMenuBreakpointCollapsed,
        sessionExpired,
        setSessionExpired,
        languages,
        userDataFirebase,
        setUserDataFirebase,
        pictureProfileUrl,
        setPictureProfileUrl,
        t,
        tenantsAssociatedToUser,
        getTenantsAssociatedWithUser,
        selectedTenantId,
        setSelectedTenantId,
        availableQuota,
        getAvailableQuota,
        notifications,
        setNotifications,
        isNumeric,
        seenToursRef,
      }}
    >
      <IonApp>
        <ConfigProvider locale={currentLanguage}>
          <NotificationContextProvider>
            <TourContextProvider>
              {!showLoading ? (
                <IonReactRouter>
                  <IonRouterOutlet>
                    <StrictMode>
                      <AppContent
                        onRouteChange={(value) => setCurrentRoute(value)}
                      />
                      <WhatsAppFloatingActionButton
                        phoneNumber={whatsAppNumber}
                      />
                      <FreshSurvey />
                    </StrictMode>
                  </IonRouterOutlet>
                </IonReactRouter>
              ) : (
                <PageLoading />
              )}
            </TourContextProvider>
          </NotificationContextProvider>
        </ConfigProvider>
      </IonApp>
    </ContextApp.Provider>
  );
};

export default App;
