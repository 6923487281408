import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ProTable, { ActionType } from '@ant-design/pro-table';
import { Input, Switch } from 'antd';

import { ExportableColumn } from '../../shared/Exporter';
import { ContextApp } from '../../contexts/ContextApp';
import { removeAccents } from '../../shared/removeAccents';

import './Functionalities.less';
import { IAppSetting } from '../../interfaces';
import { EnumsValues } from '../../enums/EnumsValues';
import GraphqlService from '../../services/graphql/GraphqlService';
import {
  IFeatureFlag,
  IFeatureFlagConfiguration,
} from '../../interfaces/FeatureFlag';
import { PageLoading } from '@ant-design/pro-layout';
import { GenericCard } from '../../components/common/GenericCard/GenericCard';
import ToolbarFilterOrder from '../../components/common/ToolbarFilterOrder/ToolbarFilterOrder';
import { Tools } from '../../shared';

export const Functionalities = () => {
  const { t } = useContext(ContextApp);
  const { customRequest, Query } = GraphqlService();
  const [contactLink, setContactLink] = useState('');
  const [featureFlagsConfig, setFeatureFlagsConfig] =
    useState<IFeatureFlag[]>();
  const [loading, setLoading] = useState(true);
  const [functionalities, setFunctionalities] = useState<IFeatureFlag[]>();
  const actionRef = useRef<ActionType>();

  const getContactLink = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.ContactLinkOnPackPage },
        },
      });
      setContactLink(data.setting_value);
    } catch (error) {
      //intentional
    }
  };

  const getFeatureFlags = async () => {
    try {
      const data: IFeatureFlagConfiguration = await customRequest({
        query: Query.getAllFeatureFlagsConfig,
      });
      if (data.featureFlagConfiguration) {
        setFeatureFlagsConfig(data.featureFlagConfiguration);
        setFunctionalities(data.featureFlagConfiguration);
      }
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    await Promise.all([getFeatureFlags(), getContactLink()]);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = useCallback(
    (): ExportableColumn<IFeatureFlag>[] => [
      {
        title: t('functionalities.columns.name'),
        dataIndex: 'name',
        key: 'name',
        export: false,
        render: (_, record) => (
          <div>
            {record.translation_key_name
              ? t(record.translation_key_name as never)
              : record.name}
          </div>
        ),
        renderFormItem: () => (
          <Input
            allowClear
            className="name-input"
            placeholder={t('functionalities.placeholder.name')}
          />
        ),
      },
      {
        title: t('functionalities.columns.description'),
        dataIndex: 'description',
        key: 'description',
        hideInSearch: true,
        export: false,
        render: (_, record) => (
          <div>
            {record.translation_key_description
              ? t(record.translation_key_description as never)
              : record.description}
          </div>
        ),
      },
      {
        title: t('functionalities.columns.visible'),
        dataIndex: 'hidden',
        key: 'hidden',
        render: (_: any, record) => (
          <Switch
            className="protable-functionalities__switch"
            checked={!record.hidden}
            disabled
          />
        ),
        hideInSearch: true,
        export: false,
      },
      {
        title: t('functionalities.columns.active'),
        dataIndex: 'available',
        key: 'available',
        render: (_: any, record) => (
          <Switch
            className="protable-functionalities__switch"
            checked={record.available}
            disabled
          />
        ),
        hideInSearch: true,
        export: false,
      },
    ],
    [functionalities],
  );

  const resetValues = () => {
    setFunctionalities(featureFlagsConfig);
  };

  const handleFilter = (filters: any) => {
    if (
      Tools.isDefined(filters) &&
      Object.values(filters).some((v) => Tools.isDefined(v)) &&
      featureFlagsConfig
    ) {
      Object.entries(filters).forEach(([key, value]) => {
        if (!Tools.isDefined(value)) delete filters[key];
      });

      const filteredFunctionalities = featureFlagsConfig.filter((item: any) => {
        return Object.entries(filters).every(([key, value]) => {
          if (typeof value === 'string') {
            return (
              item[key] && item[key].toLowerCase().includes(value.toLowerCase())
            );
          } else {
            return item[key] === value;
          }
        });
      });
      setFunctionalities(filteredFunctionalities);
    }
  };

  return loading && !functionalities ? (
    <PageLoading />
  ) : (
    <>
      <GenericCard
        icon={
          <span className="material-symbols-outlined functionalities-error-icon">
            error
          </span>
        }
        description={
          <p className="message-info-disabled">
            {t('featureFlag.wrapper.functionDisabled')}&nbsp;{' '}
            <b>
              <u>
                <a href={contactLink}>{t('featureFlag.wrapper.contactUs')}</a>
              </u>
            </b>
          </p>
        }
        className="message-warning-disabled-container functionalities-message-container"
      />

      <div className="protable-functionalities--wrapper">
        <p className="protable-functionalities--wrapper__description">
          {t('functionalities.description')}
        </p>
        <ProTable
          actionRef={actionRef}
          className="protable-functionalities"
          columns={columns()}
          dataSource={functionalities}
          pagination={false}
          options={{ reload: false, setting: false, density: false }}
          search={false}
          onSubmit={(params) => {
            if (!params.name) return;
            setFunctionalities(
              featureFlagsConfig?.filter((item) =>
                removeAccents(item.name)
                  .toLowerCase()
                  .includes(removeAccents(params.name)?.toLowerCase()),
              ),
            );
          }}
          toolBarRender={() => [
            <ToolbarFilterOrder
              showButtonNew={false}
              columns={columns()}
              onFinishFilter={(values) => {
                handleFilter(values);
              }}
              onClickButtonClean={resetValues}
            />,
          ]}
        />
      </div>
    </>
  );
};
