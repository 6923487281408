import { Input, Tooltip } from 'antd';
import React, { useState, useRef, useContext } from 'react';
import ProTable, { ProColumns, ActionType } from '@ant-design/pro-table';
import { SorterResult } from 'antd/es/table/interface';
import { IAppSetting } from '../../interfaces/AppSetting';
import GraphqlService from '../../services/graphql/GraphqlService';
import { SaveForm } from '../../components/common/ABM';
import { ABM, Authorization, Tools } from '../../shared';
import ms from 'ms';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import './AppSettingPage.less';
import { notificationContext } from '../../contexts/NotificationContext';
/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = [
  'screen_name',
  'setting_value',
  'description',
  'setting_name',
];
const LIST_SORTER = ['screen_name'];

/**
 * @description ABM Setting
 */

const AppSettingPage: React.FC<{}> = () => {
  const { openNotification } = useContext(notificationContext);
  const { Query, Mutation, customRequest } = GraphqlService();
  const { functions, t } = useContext(ContextApp);
  const [dataTable, setDataTable] = useState<IAppSetting[]>([]);
  const [sorter, setSorter] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] = useState<any>({});
  const actionRef = useRef<ActionType>();
  const [formLoading, setFormLoading] = useState(false);
  const variables = useRef<any>({});

  /**
   * Se configura por cada ABM diferente
   */
  const TITLE_UPDATE_FORM = `${t('action.modify')} ${t(
    'entity.setting',
  ).toLocaleLowerCase()}`;

  const { mobileOnSizeChangeProTable, showComponent } = useProTableForMobile({
    layout: 'horizontal',
  });

  const { TextArea } = Input;

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const request = async (params: any) => {
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};
    const search: any = ABM.valuesResult(params);

    if (searchText) {
      variables.current.searchText = searchText;
    } else {
      delete variables.current.searchText;
    }

    LIST_FILTER.forEach((element) => {
      try {
        if (search[element]) {
          if (!variables.current.filter) {
            variables.current.filter = {};
          }
          variables.current.filter[element] = search[element];
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    LIST_SORTER.forEach((element) => {
      try {
        if (search.sorter[element]) {
          if (!variables.current.orderBy) {
            variables.current.orderBy = {};
          }
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search.sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });
    try {
      const data: IAppSetting[] = await customRequest({
        query: Query.getAppSettings,
        variables: variables.current,
      });
      setDataTable(data);
      return {
        current: 1,
        data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  /**
   * @description update setting
   */
  const updateSetting = async (value: any) => {
    setFormLoading(() => true);
    if (
      editForm.setting_name ===
        EnumsValues.SettingNames.SecurityTokenExpiresin ||
      editForm.setting_name ===
        EnumsValues.SettingNames.SecurityRefreshTokenExpiresin
    ) {
      if (ms(value.setting_value) === undefined) {
        setFormLoading(() => false);
        return openNotification({
          type: 'error',
          msj: t('appSetting.invalidTimeStamp'),
          context: 'AppSetting.updateSetting.1',
        });
      }
      if (
        editForm.setting_name ===
        EnumsValues.SettingNames.SecurityTokenExpiresin
      ) {
        const refreshTokenExpiresin = dataTable.find(
          (setting) =>
            setting.setting_name ===
            EnumsValues.SettingNames.SecurityRefreshTokenExpiresin,
        )!.setting_value;
        const tokenExpiresin = value.setting_value;
        if (
          refreshTokenExpiresin &&
          Number(ms(tokenExpiresin)) > Number(ms(refreshTokenExpiresin))
        ) {
          setFormLoading(() => false);
          return openNotification({
            type: 'error',
            msj: t('error.abm.tokenExpiresInLongerThanRefreshTokenMessage'),
            context: 'AppSetting.updateSetting.2',
          });
        }
      } else if (
        editForm.setting_name ===
        EnumsValues.SettingNames.SecurityRefreshTokenExpiresin
      ) {
        const tokenExpiresin = dataTable.find(
          (setting) =>
            setting.setting_name ===
            EnumsValues.SettingNames.SecurityTokenExpiresin,
        )!.setting_value;
        const refreshTokenExpiresin: string = value.setting_value;
        if (tokenExpiresin && ms(tokenExpiresin) > ms(refreshTokenExpiresin)) {
          setFormLoading(() => false);
          return openNotification({
            type: 'error',
            msj: t('error.abm.tokenExpiresInLongerThanRefreshTokenMessage'),
            context: 'AppSetting.updateSetting.2',
          });
        }
      }
    }
    try {
      await customRequest({
        mutation: Mutation.updateAppSetting,
        variables: { input: { ...value, id: editForm.id } },
      });
      openNotification({
        type: 'success',
        msj: t('message.updateSuccess'),
        context: 'TableSetting.updateSetting.4',
      });
      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error) {
      openNotification({
        type: 'error',
        msj: t('error.abm.update'),
        context: 'TableSetting.updateSetting.5',
      });
    }
    setFormLoading(() => false);
  };

  const columns: ProColumns<IAppSetting>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      hideInTable: true,
      hideInSearch: true,
      hideInForm: true,
      fixed: 'left',
      width: 1,
    },
    {
      title: t('entity.screenName'),
      dataIndex: 'screen_name',
      sorter: true,
      hideInSearch: false,
      hideInForm: true,
      hideInTable: false,
      render: (_: any, record: IAppSetting) => (
        <Tooltip placement="top" title={record?.screen_name || ''}>
          <div translate='no'>{record?.screen_name || ''}</div>
        </Tooltip>
      ),
    },
    {
      title: t('entity.value'),
      dataIndex: 'setting_value',
      hideInSearch: true,
      hideInTable: false,
      render: (_: any, record: IAppSetting) => (
        <Tooltip placement="top" title={record?.setting_value || ''}>
          <div>{record?.setting_value || ''}</div>
        </Tooltip>
      ),
      renderFormItem: () => (
        <Input
          placeholder={`${t('action.input.enter')} ${t(
            'entity.value',
          ).toLocaleLowerCase()}`}
          maxLength={200}
        />
      ),
    },
    {
      title: t('entity.description'),
      dataIndex: 'description',
      hideInSearch: true,
      hideInForm: false,
      hideInTable: false,
      render: (_: any, record: IAppSetting) => record?.description || '',
      renderFormItem: () => (
        <TextArea
          rows={4}
          placeholder={`${t('action.input.enter')} ${t(
            'entity.description',
          ).toLocaleLowerCase()}`}
          maxLength={200}
        />
      ),
    },
    {
      title: t('entity.settingName'),
      dataIndex: 'setting_name',
      hideInSearch: true,
      hideInForm: true,
      hideInTable: false,
      render: (_: any, record: IAppSetting) => (
        <Tooltip placement="top" title={record?.setting_name || ''}>
          <div translate='no'>{record?.setting_name || ''}</div>
        </Tooltip>
      ),
    },
    {
      title: t('entity.op'),
      dataIndex: 'option',
      valueType: 'option',
      fixed: 'right',
      width: 50,
      render: (_, record: IAppSetting) => (
        <>
          {Authorization.security(
            functions,
            EnumsValues.Functions.AppSettingUpdate,
          ) ? (
            <Tooltip
              key="edit_setting"
              title={`${t('action.modify')} ${t(
                'entity.setting',
              ).toLocaleLowerCase()}`}
            >
              <span
              translate='no'
                className="material-symbols-outlined pointer appSetting-action-icon"
                hidden={record.is_readonly}
                onClick={() => {
                  handleUpdateModalVisible(true);
                  setEditFormValues(() => record);
                }}
              >
                edit
              </span>
            </Tooltip>
          ) : null}
        </>
      ),
    },
  ];

  let LIST_FILTER_NAMES = columns
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  const INPUT_SEARCH_PLACEHOLDER = LIST_FILTER_NAMES.join(', ');

  return (
    <>
      <ProTable<IAppSetting>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        actionRef={actionRef}
        rowKey="id"
        size="small"
        options={{
          density: false,
          reload: false,
        }}
        search={false}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IAppSetting>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <div className="content-search-table" key="searchtext">
            <Tooltip
              title={`${t('action.searchBy')} ${LIST_FILTER_NAMES.join(', ')}`}
            >
              <Input.Search
                size="middle"
                placeholder={INPUT_SEARCH_PLACEHOLDER}
                enterButton
                value={searchText}
                onSearch={handleSearch}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                allowClear={true}
              />
            </Tooltip>
          </div>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns}
      />

      {editForm && (
        <SaveForm
        style={{minWidth: '448px'}}
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={editForm}
          columns={columns}
          onOk={async (value) => updateSetting(value)}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues({});
          }}
          saveFormFooterIcon={{
            reset: <></>,
          }}
          buttonCancel={true}
          buttonReset={false}
        />
      )}
    </>
  );
};

export default AppSettingPage;
