import './MethodsPage.less';
import { useContext, useEffect, useState, useCallback, useRef } from 'react';
import { ContextApp } from '../../contexts/ContextApp';
import { GenericCard } from '../../components/common/GenericCard/GenericCard';
import { EnumsValues } from '../../enums/EnumsValues';
import { IAppSetting, ITenant } from '../../interfaces';
import GraphqlService from '../../services/graphql/GraphqlService';
import { CardsContainer } from '../../components/common/CardsContainer/CardsContainer';
import { IIntegration } from '../../interfaces/Integration';
import { Button, Image } from 'antd';
import ConfigureIntegrationModal from '../../components/common/ConfigureIntegrationModal/ConfigureIntegrationModal';
import { IConfigureIntegrationModalRef } from '../../interfaces/ConfigureIntegrationModalRef';
import useNotification from '../../hooks/useNotification';
import { ContextWizard } from '../../contexts/ContextWizard';
import { useLocation } from 'react-router';
import { NavContext } from '@ionic/react';
import { FeatureFlagWrapper } from '../FeatureFlagWrapper/FeatureFlagWrapper';
import useFeatureFlagConfig from '../FeatureFlagWrapper/hooks/useFeatureFlagConfig';

export const MethodsPage = () => {
  const { t, selectedTenantId } = useContext(ContextApp);
  const location = useLocation();
  const { checkPackPurchaseStep, nextStepWizard } = useContext(ContextWizard);
  const { navigate } = useContext(NavContext);
  const { customRequest, Query } = GraphqlService();
  const [contactLink, setContactLink] = useState('');
  const [integrations, setIntegrations] = useState<IIntegration[]>([]);
  const [selectedIntegration, setSelectedIntegration] = useState<number>();
  const [tenant, setTenant] = useState<ITenant>();
  const configureIntegrationModalRef =
    useRef<IConfigureIntegrationModalRef>(null);
  const { refreshNotification } = useNotification({});
  const [showButton, setShowButton] = useState(false);
  const [connectLink, setConnectLink] = useState(
    process.env.REACT_APP_BACKEND_APIDOCS || '',
  );

  const isWizardPage = location.pathname.includes('wizard');

  const check = async (tenant_id: number) => {
    const isValid: true | undefined = await checkPackPurchaseStep(tenant_id);
    if (!isValid) {
      navigate('/wizard/packs');
    }
  };

  useEffect(() => {
    if (isWizardPage && selectedTenantId) {
      check(selectedTenantId);
    }
  }, [selectedTenantId]);

  const { isAvailable } = useFeatureFlagConfig({
    featureFlag: EnumsValues.FeatureFlags.methodsPage,
  });

  const getIntegration = async () => {
    try {
      const data: IIntegration[] = await customRequest({
        query: Query.integrations,
        variables: {
          orderBy: {
            field: 'order',
            direction: 'asc',
          },
        },
      });

      setIntegrations(data);
    } catch (error) {
      //intentional
    }
  };

  useEffect(() => {
    getIntegration();
  }, []);

  const getContactLink = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.ContactLinkOnIntegrationPage },
        },
      });
      setContactLink(data.setting_value);
    } catch (error) {
      //intentional
    }
  };

  const getAPIConnectLink = async () => {
    //TODO: Ver si seguiremos obteniendo esta URl desde environment, o desde back a través de appSetting o un método específico
    setConnectLink(
      process.env.REACT_APP_BACKEND_APIDOCS
        ? process.env.REACT_APP_BACKEND_APIDOCS
        : '',
    );
  };

  useEffect(() => {
    getContactLink();
    getAPIConnectLink();
  }, []);

  const getTenant = async (tenant_id: number) => {
    try {
      const data: ITenant = await customRequest({
        query: Query.tenant,
        variables: {
          input: {
            id: tenant_id,
          },
        },
      });
      setTenant(data);
      setSelectedIntegration(data.integration_id);
    } catch {
      // Intentional
    }
  };

  useEffect(() => {
    if (selectedTenantId) {
      getTenant(selectedTenantId);
    }
  }, [selectedTenantId]);

  const handleButtonClick = async ({
    id,
    integration_status_id,
  }: IIntegration) => {
    switch (integration_status_id) {
      case EnumsValues.IntegrationStatus.Contact:
        return window.open(contactLink);
      case EnumsValues.IntegrationStatus.Connect:
        if (connectLink) {
          return window.open(connectLink);
        } else {
          return;
        }

      default:
        const thisIntegration = integrations.find((item) => item.id === id);
        if (thisIntegration) {
          return configureIntegrationModalRef.current?.openModal(
            thisIntegration,
            tenant,
          );
        }
    }
  };

  useEffect(() => {
    if (tenant?.integration_id) {
      setSelectedIntegration(tenant.integration_id);
    }
    if (tenant?.integration_id && selectedTenantId) {
      setShowButton(true);
    }
  }, [tenant]);

  const cardHeaderJsx = (value: IIntegration) => {
    switch (value.integration_status_id) {
      case EnumsValues.IntegrationStatus.Active:
        return (
          <div className="card-note has-discount">
            <div>{t('integration.active')}</div>
          </div>
        );
      case EnumsValues.IntegrationStatus.ComingSoon:
        return (
          <div className="card-note coming-soon">
            <div>{t('integration.comingSoon')}</div>
          </div>
        );
      case EnumsValues.IntegrationStatus.Connect:
        return (
          <div className="card-note connect">
            <div>{t('integration.connect')}</div>
          </div>
        );
      default:
        return <></>;
    }
  };

  const cardBodyJsx = (value: IIntegration) => (
    <>
      <div className="CardToSelect--image">
        {value.logo ? (
          <Image src={value.logo} alt={value.name} preview={false} />
        ) : (
          <div>{value.name}</div>
        )}
      </div>
    </>
  );

  const buttonLabel = (value: IIntegration) => {
    switch (value.integration_status_id) {
      case EnumsValues.IntegrationStatus.Contact:
        return t('integration.contact');
      case EnumsValues.IntegrationStatus.Connect:
        return t('integration.connect');
      default:
        return t('integration.setUp');
    }
  };

  const onConfigurationFinish = () => {
    refreshNotification();
    if (selectedTenantId) {
      getTenant(selectedTenantId);
    }
  };

  const onContinue = useCallback(() => {
    nextStepWizard();
  }, []);

  return (
    <>
      {tenant?.integration?.code === EnumsValues.IntegrationCode.braulioAPI &&
      !isAvailable ? (
        <GenericCard
          icon={<span className="material-symbols-outlined MethodsPage-info-icon" translate='no'>info</span>}
          description={
            <>
              <p className="message-info-disabled">
                <b>{t('featureFlag.methodsPage.documentationTitle')}</b>
              </p>
              <p className="message-info-disabled">
                {t('featureFlag.methodsPage.documentationInfo1')}&nbsp;{' '}
                <b>
                  <u>
                    <a href={connectLink} target="_blank" rel="noreferrer">
                      {t('featureFlag.methodsPage.documentationInfo2')}
                    </a>
                  </u>
                </b>
              </p>
            </>
          }
          className="message-info-disabled-container MethodsPage-message-container"
        />
      ) : null}

      <FeatureFlagWrapper featureFlag={EnumsValues.FeatureFlags.methodsPage}>
        <div className="MethodsPage-container">
          <GenericCard
            icon={
              <span className="material-symbols-outlined MethodsPage-info-icon" translate='no'>info</span>
            }
            title={t('integration.youDontFindIntegration')}
            description={t('integration.contactUs')}
            button={
              <Button type="default" onClick={() => window.open(contactLink)}>
                {t('PacksPage.contact')}
              </Button>
            }
            className="MethodsPage-message-container"
          />
          <CardsContainer<IIntegration>
            data={integrations}
            information={{
              title: t('integration.chooseTheIntegrationMethod'),
              description: t('integration.ConfigureIntegrationMethod'),
            }}
            cardHeaderJsx={cardHeaderJsx}
            cardBodyJsx={cardBodyJsx}
            buttonLabel={buttonLabel}
            buttonLabelIfSelected={t('integration.setUp')}
            cardDivider={false}
            selectedIntegration={selectedIntegration}
            onClick={handleButtonClick}
            extraButton={
              showButton &&
              isWizardPage && (
                <Button type="default" onClick={onContinue}>
                  {t('action.continue')}
                </Button>
              )
            }
          />
          <ConfigureIntegrationModal
            onFinish={onConfigurationFinish}
            ref={configureIntegrationModalRef}
          />
        </div>
      </FeatureFlagWrapper>
    </>
  );
};
